
@include critical {

	.c-form__input,
	input.Input,
	.StripeElement { /* Stripe class */
		display: block;
		box-sizing: border-box;
		border: 2px solid #9b9b9b;
		border-radius: 0;
		color: $cCheckoutFormInputColor;
		font-family: $cCheckoutFormInputFontFamily;
		font-size: 1rem;
		line-height: 1.125rem;
		margin: 0;
		padding: rem(15px) rem(12px);
		width: 100%;
		max-width: 400px;
	}

	.c-form__input--select {
		background: $colorWhite url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
		@include appearance-none();
	}

	.c-form__input--select option {
		background-color: #FFF;
	}

	.c-form__label .StripeElement {
		margin-top: rem(5px);
	}

	.c-form__input[disabled] {
		opacity: 1;
		color: $cCheckoutFormInputColor;
		background-color: $formTextTextareaSelectUploadDisabledBackgroundColor;
		-webkit-text-fill-color: $formTextTextareaSelectUploadFocusColor;
	}

	.c-form__input--noresize {
		resize: none;
	}

	.c-form__label {
		display: block;
		cursor: pointer;
		margin: 0 0 rem(5px) 0;
		font-size: rem(16px);
		@include bold();
	}

	.theme-OnlyRadiators .c-form__label {
		font-family: MuseoSansRounded-500, Arial, Helvetica, sans-serif;
	}

	.theme-Drench .c-form__label--thin,
	.theme-OnlyRadiators .c-form__label--thin {
		@media (min-width: 900px) {
			letter-spacing: -0.25px;
		}
	}

	.c-form__label--large {
		margin: 0 0 rem(15px) 0;
	}

	.c-form__label--has-counter {
		display: flex;
		max-width: 400px;
	}

	.c-form__label--has-counter .c-form__label-suffix {
		margin-left: rem(3px);
	}

	.c-form__label-suffix {
		font-weight: normal;
	}

	.c-form__label-counter {
		margin-left: auto;
	}

	.c-form__label-counter--over {
		color: $formErrorColor;
	}

	.c-form__label-tooltip-container {
		display: flex;

		.c-tooltip {
			margin-left: rem(5px);
			margin-top: -3px;

			@media (min-width: 900px) {
				margin-left: auto;
			}
		}
	}

	.c-form__row {
		position: relative;
		margin: 0 0 rem(20px) 0;
	}

	.c-form__row--large {
		margin: 0 0 rem(30px) 0;
		padding-top: rem(10px);
	}

	.c-form__row--last {
		margin-bottom: 0;
	}

	.c-form__error {
		display: block;
		color: $formErrorColor;
		margin: rem(5px) 0 0 0;
		font-size: rem(15px);
		font-weight: normal;
		font-family: $fontBase;
	}

	.c-form__radio-label {
		position: relative;
		display: block;
		margin-bottom: rem(10px);
		padding-bottom: 0;
		cursor: pointer;
		font-size: rem(16px);
		/*@include bold();*/
	}

	.c-form__radio-label.is-loading {
		.c-loader-round {
			position: absolute;
			top: 10px;
			left: 0;
			min-width: 24px;
			margin: 0;
		}
	}

	.c-form__radio-label.is-loading:not(.is-checked) {
		cursor: not-allowed;
	}

	.c-form__radio-text {
		position: relative;
		z-index: 101;
		line-height: 1.2;
	}

	.c-form__radio-text-extra {
		display: block;
	}

	.c-form__radio-label--block {
		padding: rem(15px);
		border: 1px solid #4a5050;
		max-width: 400px;
		box-sizing: border-box;
	}

	.c-form__radio-label--block .c-form__radio-input {
		z-index: 100;
		opacity: 0.01;
		position: absolute;
		top: 0;
		left: 0;
	}

	.c-form__radio-label--block .c-form__radio-input:checked ~ .c-form__radio-highlight {
		position: absolute;
		z-index: 99;
		top: 0;
		left: 0;
		right: 0;
		display: block;
		background-color: #4a5050;
		height: 100%;
		width: 100%;
	}

	.c-form__radio-label--block .c-form__radio-input:checked ~ .c-form__radio-text {
		color: #FFF;
	}

	.c-form__radio-label--circle {
		padding-left: 30px;
		margin-bottom: 20px;
		max-width: 500px;
	}

	.c-form__radio-label--circle--payment {
		position: relative;
	}

	.c-form__radio-label--circle .c-form__radio-circle {
		box-sizing: border-box;
		height: 24px;
		width: 24px;
		border-radius: 100%;
		border: 1px solid $cCheckoutFormInputFocusBorderColor;
		position: absolute;
		top: -1px;
		left: 0;
	}

	.c-form__radio-label--circle .c-form__radio-input.focus-visible ~ .c-form__radio-circle {
		outline: 2px solid $cCheckoutFormInputFocusBorderColor;
	}

	.c-form__radio-label--circle .c-form__radio-input {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
		opacity: 0.01;
	}

	.c-form__radio-label--circle .c-form__radio-highlight {
		position: absolute;
		top: 5px;
		left: 6px;
		height: 12px;
		width: 12px;
		opacity: 0;
		border-radius: 100%;
		background-color: $cCheckoutFormInputFocusBorderColor;
		transition: opacity 300ms;
	}

	.c-form__radio-label--circle .c-form__radio-input:checked ~ .c-form__radio-highlight {
		opacity: 1;
	}

	.c-form__radio-label--delivery .c-form__radio-text-extra {
		display: inline-block;
	}

	.c-form__input-with-button {
		display: flex;
		max-width: 400px;
	}

	.c-form__input-with-button .c-button {
		white-space: nowrap;
		margin-left: rem(5px);
		height: inherit;
		line-height: inherit;
	}

	.c-form__add-input {
		display: inline-block;
		background-color: transparent;
		padding: 0;
		margin: 0 0 rem(15px) 0;
		text-decoration: underline;
		border: none;
		font-family: inherit;
		font-size: inherit;
		cursor: pointer;
		@include bold();
		color: $cCheckoutFormAddInputColor;
	}

	.c-form__add-input:hover,
	.c-form__add-input:focus {
		color: $cCheckoutFormAddInputColor;
		text-decoration: none;
	}

	.c-form__password-link {
		display: inline-block;
		background-color: transparent;
		padding: 0;
		text-decoration: underline;
		border: none;
		font-family: inherit;
		font-size: inherit;
		cursor: pointer;
		font-weight: 400;
		color: $cCheckoutCardLinkColor;
	}

	.c-form__password-link:hover,
	.c-form__password-link:focus {
		color: $cCheckoutCardLinkColorHover;
		text-decoration: none;
	}

	.c-form__checkbox-container {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		height: 32px;
		width: 32px;
		border: 2px solid #9b9b9b;
	}

	.c-form__checkbox-container input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.01;
	}

	.c-form__checkbox-icon {
		opacity: 0;
		color: $cCheckoutFormCheckoutColor;
		height: 20px;
		width: 20px;
		transition: opacity 300ms;
	}

	.c-form__checkbox-container input:checked ~ .c-form__checkbox-icon {
		opacity: 1;
	}

	.c-form__label--checkbox {
		display: flex;
		align-items: center;
	}

	.c-form__checkbox-text {
		margin-left: 10px;
		font-weight: normal;
	}

	.c-form__disclaimer {
		margin: 0 0 rem(20px) 0;
	}

	.c-form__disclaimer--sidebar {
		margin: rem(20px) 0 0 0;
	}

	.c-form__split-fields-item {
		margin-bottom: 20px;
	}

	@media (min-width: 580px) {

		.c-form__split-fields {
			display: flex;
			max-width: 400px;
		}

		.c-form__split-fields-item {
			flex: 1;
			margin-bottom: 0;
		}

		.c-form__split-fields-item--expiry {
			width: 50%;
			flex: none;
		}

		.c-form__split-fields-item--expiry .StripeElement {
			width: 190px;
		}

		.c-form__split-fields-item--security {
			margin-left: auto;
			flex: none;
		}

		.c-form__split-fields-item--security .StripeElement {
			width: 190px;
		}
	}

	.c-form__input-with-loader {
		position: relative;
	}

	.c-form__input-with-loader .c-form__input {
		padding-right: 40px;
	}

	.c-form__input-with-loader .c-loader-round {
		position: absolute;
		top: 26px;
		right: 10px;
	}

	@media (min-width: 500px) {
		.c-form__input-with-loader {
			max-width: 400px;
		}
	}

	.c-form__input-password-container {
		display: flex;
	}

	.c-form__input-password-button {
		border: none;
		background-color: transparent;
		color: inherit;
		cursor: pointer;
	}

	@media (max-width: 500px) {
		.c-checkout .c-form__email-me-button {
			margin-left: 0;
			margin-top: rem(10px);
		}
	}

	.c-form__input-error-message-placeholder {
		padding-bottom: 23px; // Height of error message
	}

	.c-form__narrow {
		max-width: 400px;
		margin: 0 auto;
		text-align: left;
	}
}

@include defer {
	.c-form__input:focus,
	input.Input:focus,
	.StripeElement:focus,
	.StripeElement--focus {
		border-radius: 0;
		outline: none;
		color: #475257;
		border-color: $cCheckoutFormInputFocusBorderColor;
	}

	.c-form__stripe-request-button .StripeElement {
		border: none;
		padding: 0;
		max-width: 460px;
	}

	.c-form__stripe-request-button-or {
		@include bold();
		margin: 0 0 rem(20px) 0;
		max-width: 460px;
		text-align: center;
		font-size: rem(17px);
	}

	.c-form__payment-button {
		position: relative;
		display: flex;
		align-items: center;
		font-family: inherit;
		font-size: 1rem;
		@include bold();
		color: inherit;
		background-color: $colorWhite;
		padding: rem(15px);
		width: 100%;
		max-width: 460px;
		border: 1px solid #9b9b9b;
		text-align: left;
		cursor: pointer;

		@media (min-width: 580px) {
			padding: rem(15px) rem(30px);
		}
	}

	.c-form__payment-button.is-selected {
		border-color: $colorBase;
	}

	.c-form__payment-button-text {
		flex: 1;
	}

	.c-form__payment-button-subtext {
		display: block;
		font-size: rem(14px);
		margin: 5px 5px 0 0;
	}

	.c-form__payment-button-images {
		margin-left: auto;
		display: flex;
		flex-shrink: 0;
	}

	.c-form__payment-container {
		border: 1px solid $colorBase;
		border-top: none;
		padding: rem(15px);
		max-width: 460px;
		margin: -20px 0 20px 0;

		@media (min-width: 580px) {
			padding: rem(30px);
		}
	}

	.c-form__paypal-button {
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		padding: rem(14px);
		background-color: #ffc439;
		font-size: 1rem;
		@include bold();
		color: inherit;
		border: none;
		cursor: pointer;
		transition: background-color 300ms;
	}

	.c-form__paypal-button:hover,
	.c-form__paypal-button:focus {
		background-color: #f2ba36;
	}

	.c-form__paypal-button-image {
		display: inline-block;
		vertical-align: middle;
		margin-left: 6px;
	}

	.c-form__paypal-button-text {
		display: inline-block;
		vertical-align: middle;
		font-size: rem(18px);
	}

	.c-form__centred-row {
		margin: 0 auto;
		text-align: left;
		max-width: 400px;
	}
}
