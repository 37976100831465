.c-umbraco-form legend {

	@include critical {
		padding-bottom: 10px;
		text-align: center;
	}
}

.c-umbraco-form .checkboxlist,
.c-umbraco-form .radiobuttonlist {

	@include critical {
		list-style: none;
		margin: 10px 0 0 0;
		padding: 0;

		li {
			padding: 0 0 10px 0;
		}

		li:last-child {
			padding-bottom: 0;
		}

		label {
			padding: 0;
			display: flex;
			align-items: center;
		}

		input {
			margin: 0 5px 0 0;
		}
	}
}

.c-umbraco-form .field-validation-error {

	@include defer {
		display: block;
		color: $formReqColor;
		background: none;
		font-size: rem(13px);
		line-height: rem(17px);
		margin: 0;
		padding: 5px 0 0 0;
		max-width: 315px;
	}
}

.umbraco-forms-errormessage {
	color: $colorEmphasise;
	padding-bottom: 20px;
}

.umbraco-forms-errormessage p {
	padding: 0 0 10px 0;
}

.umbraco-forms-tooltip {

	@include critical {
		display: block;
		margin: 0 0 5px 0;
		font-size: rem(13px);
	}
}

.umbraco-forms-field.checkbox {

	@include critical {
		position: relative;
	}
}

.umbraco-forms-field.checkbox .umbraco-forms-label {

	@include critical {
		padding-left: 22px;
	}
}

.umbraco-forms-field.checkbox .umbraco-forms-field-wrapper {

	@include critical {
		position: absolute;
		top: 0;
		left: 0;
	}
}

@include critical {

	@include mq-min($bpDesktop) {
		.umbraco-forms-caption {
			text-align: center;
			margin-bottom: 20px;
		}
	}

	.c-umbraco-form {
		background-color: $formUmbracoBackgroundColor;
		padding: 20px;
		margin: 0 -20px;

		@include mq-min($bpDesktop) {
			padding: 40px 0;
			margin: 0;
		}
	}

	.umbraco-forms-page {
		margin: 0 auto;
	}

	.umbraco-forms-navigation {

		input[type="submit"] {
			min-width: 150px;
		}

		@include mq-min($bpDesktop) {
			text-align: center;
		}
	}

	.umbraco-forms-page .row-fluid {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.umbraco-forms-page .g-recaptcha {
		display: flex;
		justify-content: center;
	}

	@include mq-min($bpDesktop) {

		.umbraco-forms-page .row-fluid {
			flex-direction: row;
		}

		.umbraco-forms-page .row-fluid .c-umbraco-form__container {
			flex: 1 0 auto;
			max-width: 350px;
		}
	}

	.umbraco-forms-page .row-fluid .c-umbraco-form__container {
		padding: 0 20px;
		max-width: 400px;
		width: 100%;
		margin-bottom: auto;
	}

	@include mq-max($bpDesktop - 1) {
		.c-umbraco-form select[multiple] {
			height: 90px;
		}
	}

	@include mq-min($bpDesktop) {
		.c-umbraco-form__container.col-md-12 {
			input.text,
			textarea,
			select {
				max-width: 100%;
			}
		}

		.c-umbraco-form select[multiple] {
			height: 72px;
		}
	}

	.c-form__label--umbraco-form {
		min-height: 19px;
	}

	.titleanddescription p {
		padding: 0;
	}
}
